.index-banner {
  position: relative;
  height: 100vh;
}

@media (max-width: 1024px) {
  .index-banner {
    height: 600px;
  }
}

@media (max-width: 991px) {
  .index-banner {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index-banner {
    height: 300px;
  }
}

.index-banner .swiper-wrapper .swiper-slide {
  overflow: hidden;
  display: flex;
  align-items: center;
}

.index-banner .swiper-wrapper .swiper-slide.swiper-slide-active .slide-inner b, .index-banner .swiper-wrapper .swiper-slide.swiper-slide-duplicate-active .slide-inner b {
  transform: scale(1);
}

.index-banner .swiper-wrapper .swiper-slide .slide-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}

.index-banner .swiper-wrapper .swiper-slide .slide-inner b {
  display: block;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transition: transform 4s;
  transition-delay: .5s;
  transform: scale(1.1);
  transform-origin: center;
}

.index-banner .swiper-pagination {
  right: 140px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination {
    right: 116px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination {
    right: 92px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination {
    right: 68px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination {
    right: 44px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination {
    right: 20px;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  width: 10px;
  height: 10px;
  background-color: #fff;
  position: relative;
  transition: all .3s;
}

.index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 22px;
  }
}

@media (max-width: 1366px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 19px;
  }
}

@media (max-width: 1024px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 16px;
  }
}

@media (max-width: 991px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 13px;
  }
}

@media (max-width: 767px) {
  .index-banner .swiper-pagination .swiper-pagination-bullet:not(:last-child) {
    margin-bottom: 10px;
  }
}

.index-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #e7380d;
}

.index-banner .common-circle {
  position: absolute;
  bottom: 5%;
  right: 5%;
  z-index: 1;
  color: #fff;
}

.index-banner .common-circle .img .iconfont {
  color: #fff;
}

.index-about {
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  flex-direction: row-reverse;
  display: flex;
  padding-top: 145px;
  padding-bottom: 185px;
}

@media (max-width: 767px) {
  .index-about {
    flex-direction: column-reverse;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-top: 120px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-top: 95px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-top: 70px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-top: 45px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about {
    padding-bottom: 152px;
  }
}

@media (max-width: 1366px) {
  .index-about {
    padding-bottom: 119px;
  }
}

@media (max-width: 1024px) {
  .index-about {
    padding-bottom: 86px;
  }
}

@media (max-width: 991px) {
  .index-about {
    padding-bottom: 53px;
  }
}

@media (max-width: 767px) {
  .index-about {
    padding-bottom: 20px;
  }
}

.index-about::after {
  content: '';
  display: block;
  border-radius: 30px;
  background-color: #eef1f8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transform-origin: center;
  transform: rotate(45deg) translate3d(-40%, -30%, 0);
  width: 600px;
  height: 600px;
}

@media (max-width: 1600px) {
  .index-about::after {
    width: 520px;
  }
}

@media (max-width: 1366px) {
  .index-about::after {
    width: 440px;
  }
}

@media (max-width: 1024px) {
  .index-about::after {
    width: 360px;
  }
}

@media (max-width: 991px) {
  .index-about::after {
    width: 280px;
  }
}

@media (max-width: 767px) {
  .index-about::after {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .index-about::after {
    height: 520px;
  }
}

@media (max-width: 1366px) {
  .index-about::after {
    height: 440px;
  }
}

@media (max-width: 1024px) {
  .index-about::after {
    height: 360px;
  }
}

@media (max-width: 991px) {
  .index-about::after {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .index-about::after {
    height: 200px;
  }
}

.index-about .left {
  flex-shrink: 0;
  box-sizing: border-box;
  text-align: left;
  width: 45.8%;
  padding-left: 200px;
}

@media (max-width: 991px) {
  .index-about .left {
    text-align: center;
  }
}

@media (max-width: 991px) {
  .index-about .left {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .index-about .left {
    padding-left: 160px;
  }
}

@media (max-width: 1366px) {
  .index-about .left {
    padding-left: 120px;
  }
}

@media (max-width: 1024px) {
  .index-about .left {
    padding-left: 80px;
  }
}

@media (max-width: 991px) {
  .index-about .left {
    padding-left: 40px;
  }
}

@media (max-width: 767px) {
  .index-about .left {
    padding-left: 0px;
  }
}

.index-about .left img {
  display: block;
  height: auto;
  margin: 0;
  width: 256px;
  transform: translateX(-13%);
}

@media (max-width: 991px) {
  .index-about .left img {
    margin: 0 auto;
  }
}

@media (max-width: 1600px) {
  .index-about .left img {
    width: 236.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .left img {
    width: 217.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .left img {
    width: 198.4px;
  }
}

@media (max-width: 991px) {
  .index-about .left img {
    width: 179.2px;
  }
}

@media (max-width: 767px) {
  .index-about .left img {
    width: 160px;
  }
}

.index-about .left .t {
  font-family: 'itc-b';
  text-transform: uppercase;
  line-height: .9;
  color: #3a3a3a;
  font-size: 80px;
  margin-top: 20px;
  margin-bottom: 70px;
}

@media (max-width: 1600px) {
  .index-about .left .t {
    font-size: 70px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t {
    font-size: 60px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t {
    font-size: 50px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t {
    font-size: 40px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-about .left .t {
    margin-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t {
    margin-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t {
    margin-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t {
    margin-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .left .t {
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t {
    margin-bottom: 50px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t {
    margin-bottom: 40px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t {
    margin-bottom: 30px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t {
    margin-bottom: 20px;
  }
}

.index-about .left .t span {
  background-color: #f9be00;
  color: #fff;
  line-height: 1;
  border-radius: 50px;
  display: inline-block;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-about .left .t span {
    padding-left: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t span {
    padding-left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t span {
    padding-left: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t span {
    padding-left: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t span {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .left .t span {
    padding-right: 26px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t span {
    padding-right: 22px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t span {
    padding-right: 18px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t span {
    padding-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t span {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .left .t span {
    font-size: 52px;
  }
}

@media (max-width: 1366px) {
  .index-about .left .t span {
    font-size: 44px;
  }
}

@media (max-width: 1024px) {
  .index-about .left .t span {
    font-size: 36px;
  }
}

@media (max-width: 991px) {
  .index-about .left .t span {
    font-size: 28px;
  }
}

@media (max-width: 767px) {
  .index-about .left .t span {
    font-size: 20px;
  }
}

.index-about .right {
  flex-shrink: 0;
  font-family: 'itc-b';
  width: 54.2%;
}

@media (max-width: 991px) {
  .index-about .right {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .right {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  .index-about .right {
    width: 100%;
  }
}

.index-about .right .cont1 {
  border-bottom: 1px solid #c6cbdb;
  color: #1b3993;
  font-size: 40px;
  padding-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-about .right .cont1 {
    font-size: 36px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont1 {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont1 {
    font-size: 28px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont1 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont1 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont1 {
    padding-bottom: 34px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont1 {
    padding-bottom: 28px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont1 {
    padding-bottom: 22px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont1 {
    padding-bottom: 16px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont1 {
    padding-bottom: 10px;
  }
}

.index-about .right .cont1 .swiper .t1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.index-about .right .cont1 .swiper .t1 img {
  height: auto;
  margin-left: 10px;
  margin-right: 20px;
  width: 141px;
}

@media (max-width: 1600px) {
  .index-about .right .cont1 .swiper .t1 img {
    margin-right: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont1 .swiper .t1 img {
    margin-right: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont1 .swiper .t1 img {
    margin-right: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont1 .swiper .t1 img {
    margin-right: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont1 .swiper .t1 img {
    margin-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont1 .swiper .t1 img {
    width: 132.8px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont1 .swiper .t1 img {
    width: 124.6px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont1 .swiper .t1 img {
    width: 116.4px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont1 .swiper .t1 img {
    width: 108.2px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont1 .swiper .t1 img {
    width: 100px;
  }
}

.index-about .right .cont1 .swiper .t2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.index-about .right .cont1 .swiper .t2 img {
  margin-left: 35px;
}

@media (max-width: 1600px) {
  .index-about .right .cont1 .swiper .t2 img {
    margin-left: 30px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont1 .swiper .t2 img {
    margin-left: 25px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont1 .swiper .t2 img {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont1 .swiper .t2 img {
    margin-left: 15px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont1 .swiper .t2 img {
    margin-left: 10px;
  }
}

.index-about .right .cont2 {
  color: #1b3993;
  border-bottom: 1px solid #c6cbdb;
  padding-bottom: 20px;
  padding-top: 25px;
  font-size: 40px;
  margin-bottom: 50px;
}

@media (max-width: 1600px) {
  .index-about .right .cont2 {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 {
    padding-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 {
    padding-top: 22px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 {
    padding-top: 19px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 {
    padding-top: 16px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 {
    padding-top: 13px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 {
    font-size: 36px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 {
    font-size: 32px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 {
    font-size: 28px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 {
    margin-bottom: 42px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 {
    margin-bottom: 34px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 {
    margin-bottom: 26px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 {
    margin-bottom: 18px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 {
    margin-bottom: 10px;
  }
}

.index-about .right .cont2 .swiper .t1 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.index-about .right .cont2 .swiper .t1 img {
  width: 354px;
  margin-left: 40px;
}

@media (max-width: 1600px) {
  .index-about .right .cont2 .swiper .t1 img {
    width: 323.2px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 .swiper .t1 img {
    width: 292.4px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 .swiper .t1 img {
    width: 261.6px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 .swiper .t1 img {
    width: 230.8px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 .swiper .t1 img {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 .swiper .t1 img {
    margin-left: 35px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 .swiper .t1 img {
    margin-left: 30px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 .swiper .t1 img {
    margin-left: 25px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 .swiper .t1 img {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 .swiper .t1 img {
    margin-left: 15px;
  }
}

.index-about .right .cont2 .swiper .t2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.index-about .right .cont2 .swiper .t2 span:first-child {
  color: #e7380d;
  white-space: nowrap;
}

.index-about .right .cont2 .swiper .t2 img {
  border-radius: 10px;
  width: 220px;
  margin-left: 65px;
  margin-right: 50px;
}

@media (max-width: 1600px) {
  .index-about .right .cont2 .swiper .t2 img {
    width: 196px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 .swiper .t2 img {
    width: 172px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 .swiper .t2 img {
    width: 148px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 .swiper .t2 img {
    width: 124px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 .swiper .t2 img {
    width: 100px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-left: 53px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-left: 41px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-left: 29px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-left: 17px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-left: 5px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-right: 41px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-right: 32px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-right: 23px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-right: 14px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont2 .swiper .t2 img {
    margin-right: 5px;
  }
}

.index-about .right .cont3 {
  color: #e7380d;
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  font-size: 24px;
}

@media (max-width: 767px) {
  .index-about .right .cont3 {
    display: block;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont3 {
    font-size: 22.4px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont3 {
    font-size: 20.8px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont3 {
    font-size: 19.2px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont3 {
    font-size: 17.6px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont3 {
    font-size: 16px;
  }
}

.index-about .right .cont3 img {
  border-radius: 10px;
  height: auto;
  width: 220px;
  margin-bottom: 0;
  margin-right: 35px;
}

@media (max-width: 767px) {
  .index-about .right .cont3 img {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont3 img {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .index-about .right .cont3 img {
    margin-right: 30px;
  }
}

@media (max-width: 1366px) {
  .index-about .right .cont3 img {
    margin-right: 25px;
  }
}

@media (max-width: 1024px) {
  .index-about .right .cont3 img {
    margin-right: 20px;
  }
}

@media (max-width: 991px) {
  .index-about .right .cont3 img {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .index-about .right .cont3 img {
    margin-right: 10px;
  }
}

.index-product-pc {
  position: relative;
  overflow: hidden;
  display: block;
}

@media (max-width: 1024px) {
  .index-product-pc {
    display: none;
  }
}

.index-product-pc.fixed .color-bg, .index-product-pc.fixed .nav-cont, .index-product-pc.fixed .text-cont {
  position: fixed;
}

.index-product-pc.abs .color-bg, .index-product-pc.abs .nav-cont, .index-product-pc.abs .text-cont {
  position: absolute;
  bottom: 0;
  top: auto;
}

.index-product-pc .color-bg {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: #1b3993;
  transition: all .45s;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 93.75%;
}

@media (max-width: 1600px) {
  .index-product-pc .color-bg {
    border-top-right-radius: 46px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .color-bg {
    border-top-right-radius: 42px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .color-bg {
    border-top-right-radius: 38px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .color-bg {
    border-top-right-radius: 34px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .color-bg {
    border-top-right-radius: 30px;
  }
}

@media (max-width: 1600px) {
  .index-product-pc .color-bg {
    border-bottom-right-radius: 46px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .color-bg {
    border-bottom-right-radius: 42px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .color-bg {
    border-bottom-right-radius: 38px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .color-bg {
    border-bottom-right-radius: 34px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .color-bg {
    border-bottom-right-radius: 30px;
  }
}

.index-product-pc .nav-cont {
  width: 50%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  padding-left: 120px;
  padding-top: 10%;
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont {
    padding-left: 98px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont {
    padding-left: 76px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont {
    padding-left: 54px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont {
    padding-left: 32px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont {
    padding-left: 10px;
  }
}

.index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
  content: '';
  display: block;
  border-radius: 30px;
  background-color: #eef1f8;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  transform: rotate(45deg) translate3d(-50%, 70%, 0);
  opacity: .1;
  width: 600px;
  height: 600px;
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    width: 520px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    width: 440px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    width: 360px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    width: 280px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    width: 200px;
  }
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    height: 520px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    height: 440px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    height: 360px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    height: 280px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont::after, .index-product-pc .nav-cont::before {
    height: 200px;
  }
}

.index-product-pc .nav-cont::after {
  transform: rotate(45deg) translate3d(-58%, 58%, 0);
}

.index-product-pc .nav-cont .nav-title {
  color: #fff;
  font-size: 20px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont .nav-title {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont .nav-title {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont .nav-title {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont .nav-title {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont .nav-title {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont .nav-title {
    margin-bottom: 36px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont .nav-title {
    margin-bottom: 32px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont .nav-title {
    margin-bottom: 28px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont .nav-title {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont .nav-title {
    margin-bottom: 20px;
  }
}

.index-product-pc .nav-cont .nav-list {
  font-family: 'itc-b';
  white-space: nowrap;
  line-height: 1;
  font-size: 90px;
}

@media (max-width: 1600px) {
  .index-product-pc .nav-cont .nav-list {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .nav-cont .nav-list {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .nav-cont .nav-list {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .nav-cont .nav-list {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .nav-cont .nav-list {
    font-size: 40px;
  }
}

.index-product-pc .nav-cont .nav-list ul li {
  cursor: pointer;
  color: transparent;
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.7);
  -webkit-font-smoothing: antialiased;
  transition: all .3s;
  text-transform: uppercase;
}

.index-product-pc .nav-cont .nav-list ul li.active {
  color: #fff;
}

.index-product-pc .text-cont {
  width: 42%;
  height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.index-product-pc .text-cont .item {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: right;
  opacity: 0;
  visibility: hidden;
  transition: all .3s;
  right: 260px;
}

@media (max-width: 1600px) {
  .index-product-pc .text-cont .item {
    right: 218px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .text-cont .item {
    right: 176px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .text-cont .item {
    right: 134px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .text-cont .item {
    right: 92px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .text-cont .item {
    right: 50px;
  }
}

.index-product-pc .text-cont .item.active {
  opacity: 1;
  visibility: visible;
}

.index-product-pc .text-cont .item .t {
  color: #fff;
  line-height: 1.6;
  margin-bottom: 55px;
}

@media (max-width: 1600px) {
  .index-product-pc .text-cont .item .t {
    margin-bottom: 48px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .text-cont .item .t {
    margin-bottom: 41px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .text-cont .item .t {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .text-cont .item .t {
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .text-cont .item .t {
    margin-bottom: 20px;
  }
}

.index-product-pc .img-cont {
  padding-left: 18.22%;
}

.index-product-pc .img-cont .item {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index-product-pc .img-cont .item .img {
  width: 100%;
  height: 77%;
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 1600px) {
  .index-product-pc .img-cont .item .img {
    border-top-left-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .img-cont .item .img {
    border-top-left-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .img-cont .item .img {
    border-top-left-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .img-cont .item .img {
    border-top-left-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .img-cont .item .img {
    border-top-left-radius: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-pc .img-cont .item .img {
    border-bottom-left-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-pc .img-cont .item .img {
    border-bottom-left-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-pc .img-cont .item .img {
    border-bottom-left-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-pc .img-cont .item .img {
    border-bottom-left-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-pc .img-cont .item .img {
    border-bottom-left-radius: 10px;
  }
}

.index-product-pc .img-cont .item .img::before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 0;
  left: 0;
}

.index-product-pc .img-cont .item .img::after {
  content: '';
  display: block;
  width: 50%;
  height: 100%;
  background: linear-gradient(left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  position: absolute;
  top: 0;
  right: 0;
}

.index-product-mob {
  display: none;
}

@media (max-width: 1024px) {
  .index-product-mob {
    display: block;
  }
}

.index-product-mob .index-product-title {
  font-family: 'itc-b';
  color: #3a3a3a;
  padding-left: 260px;
  padding-right: 260px;
  font-size: 60px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-product-mob .index-product-title {
    padding-left: 210px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .index-product-title {
    padding-left: 160px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .index-product-title {
    padding-left: 110px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .index-product-title {
    padding-left: 60px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .index-product-title {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .index-product-title {
    padding-right: 210px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .index-product-title {
    padding-right: 160px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .index-product-title {
    padding-right: 110px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .index-product-title {
    padding-right: 60px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .index-product-title {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .index-product-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .index-product-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .index-product-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .index-product-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .index-product-title {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .index-product-title {
    margin-bottom: 35px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .index-product-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .index-product-title {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .index-product-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .index-product-title {
    margin-bottom: 15px;
  }
}

.index-product-mob .product-list ul li {
  position: relative;
  margin-top: 60px;
  padding-left: 120px;
  padding-top: 120px;
  padding-bottom: 120px;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li {
    margin-top: 50px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li {
    margin-top: 40px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li {
    margin-top: 30px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li {
    margin-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li {
    padding-left: 99px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li {
    padding-left: 78px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li {
    padding-left: 57px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li {
    padding-left: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li {
    padding-top: 99px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li {
    padding-top: 78px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li {
    padding-top: 57px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li {
    padding-top: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li {
    padding-top: 15px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li {
    padding-bottom: 99px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li {
    padding-bottom: 78px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li {
    padding-bottom: 57px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li {
    padding-bottom: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li {
    padding-bottom: 15px;
  }
}

.index-product-mob .product-list ul li::after {
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .45s;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  width: 93.75%;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li::after {
    border-top-right-radius: 46px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li::after {
    border-top-right-radius: 42px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li::after {
    border-top-right-radius: 38px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li::after {
    border-top-right-radius: 34px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li::after {
    border-top-right-radius: 30px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li::after {
    border-bottom-right-radius: 46px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li::after {
    border-bottom-right-radius: 42px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li::after {
    border-bottom-right-radius: 38px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li::after {
    border-bottom-right-radius: 34px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li::after {
    border-bottom-right-radius: 30px;
  }
}

.index-product-mob .product-list ul li:nth-child(1)::after {
  background-color: #1b3993;
}

.index-product-mob .product-list ul li:nth-child(2)::after {
  background-color: #e7380d;
}

.index-product-mob .product-list ul li:nth-child(3)::after {
  background-color: #f9be00;
}

.index-product-mob .product-list ul li:nth-child(4)::after {
  background-color: #fb7c2f;
}

.index-product-mob .product-list ul li:nth-child(5)::after {
  background-color: #fb7c2f;
}

.index-product-mob .product-list ul li:nth-child(6)::after {
  background-color: #17abf1;
}

.index-product-mob .product-list ul li .cont {
  position: relative;
}

.index-product-mob .product-list ul li .cont .item-img {
  overflow: hidden;
  background-position: center;
  background-size: cover;
  position: relative;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  height: 500px;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-top-left-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-top-left-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-top-left-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-top-left-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-top-left-radius: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-bottom-left-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-bottom-left-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-bottom-left-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-bottom-left-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-img {
    border-bottom-left-radius: 10px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-img {
    height: 400px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-img {
    height: 300px;
  }
}

.index-product-mob .product-list ul li .cont .item-img::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
}

.index-product-mob .product-list ul li .cont .item-t {
  box-sizing: border-box;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 1;
  transform: translateY(-50%);
  text-align: center;
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-right: 84px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-right: 68px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-right: 52px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-right: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-right: 20px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-left: 84px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-left: 68px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-left: 52px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-left: 36px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-left: 20px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-top: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-top: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-top: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-top: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t {
    padding-bottom: 10px;
  }
}

.index-product-mob .product-list ul li .cont .item-t .title {
  font-family: 'itc-b';
  line-height: 1;
  color: #fff;
  text-transform: uppercase;
  font-size: 90px;
  margin-bottom: 55px;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    font-size: 40px;
  }
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    margin-bottom: 48px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    margin-bottom: 41px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t .title {
    margin-bottom: 20px;
  }
}

.index-product-mob .product-list ul li .cont .item-t .desc {
  color: #fff;
  line-height: 1.6;
  margin-bottom: 55px;
}

@media (max-width: 1600px) {
  .index-product-mob .product-list ul li .cont .item-t .desc {
    margin-bottom: 48px;
  }
}

@media (max-width: 1366px) {
  .index-product-mob .product-list ul li .cont .item-t .desc {
    margin-bottom: 41px;
  }
}

@media (max-width: 1024px) {
  .index-product-mob .product-list ul li .cont .item-t .desc {
    margin-bottom: 34px;
  }
}

@media (max-width: 991px) {
  .index-product-mob .product-list ul li .cont .item-t .desc {
    margin-bottom: 27px;
  }
}

@media (max-width: 767px) {
  .index-product-mob .product-list ul li .cont .item-t .desc {
    margin-bottom: 20px;
  }
}

.index-marketing {
  position: relative;
  overflow: hidden;
  padding-top: 160px;
  padding-bottom: 60px;
}

@media (max-width: 1600px) {
  .index-marketing {
    padding-top: 144px;
  }
}

@media (max-width: 1366px) {
  .index-marketing {
    padding-top: 128px;
  }
}

@media (max-width: 1024px) {
  .index-marketing {
    padding-top: 112px;
  }
}

@media (max-width: 991px) {
  .index-marketing {
    padding-top: 96px;
  }
}

@media (max-width: 767px) {
  .index-marketing {
    padding-top: 80px;
  }
}

@media (max-width: 1600px) {
  .index-marketing {
    padding-bottom: 52px;
  }
}

@media (max-width: 1366px) {
  .index-marketing {
    padding-bottom: 44px;
  }
}

@media (max-width: 1024px) {
  .index-marketing {
    padding-bottom: 36px;
  }
}

@media (max-width: 991px) {
  .index-marketing {
    padding-bottom: 28px;
  }
}

@media (max-width: 767px) {
  .index-marketing {
    padding-bottom: 20px;
  }
}

.index-marketing .top-scroll-text {
  font-family: 'itc-b';
  color: #eef1f8;
  position: absolute;
  top: 0;
  right: -10%;
  white-space: nowrap;
  line-height: .7;
  font-size: 160px;
}

@media (max-width: 1600px) {
  .index-marketing .top-scroll-text {
    font-size: 140px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .top-scroll-text {
    font-size: 120px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .top-scroll-text {
    font-size: 100px;
  }
}

@media (max-width: 991px) {
  .index-marketing .top-scroll-text {
    font-size: 80px;
  }
}

@media (max-width: 767px) {
  .index-marketing .top-scroll-text {
    font-size: 60px;
  }
}

.index-marketing .common-circle {
  color: #d8d8d8;
  top: 30%;
  left: 30px;
}

@media (max-width: 991px) {
  .index-marketing .common-circle {
    top: 1%;
  }
}

@media (max-width: 1600px) {
  .index-marketing .common-circle {
    left: 26px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .common-circle {
    left: 22px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .common-circle {
    left: 18px;
  }
}

@media (max-width: 991px) {
  .index-marketing .common-circle {
    left: 14px;
  }
}

@media (max-width: 767px) {
  .index-marketing .common-circle {
    left: 10px;
  }
}

.index-marketing .common-circle .img .iconfont {
  color: rgba(0, 0, 0, 0.2);
}

.index-marketing .cont {
  max-width: 1400px;
  box-sizing: content-box;
  margin: 0 auto;
  padding-left: 140px;
  padding-right: 140px;
}

@media (max-width: 1600px) {
  .index-marketing .cont {
    padding-left: 112px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont {
    padding-left: 84px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont {
    padding-left: 56px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-marketing .cont {
    padding-right: 112px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont {
    padding-right: 84px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont {
    padding-right: 56px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont {
    padding-right: 28px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont {
    padding-right: 0px;
  }
}

.index-marketing .cont .index-marketing-title {
  font-family: 'itc-b';
  color: #3a3a3a;
  font-size: 60px;
  margin-bottom: 40px;
}

@media (max-width: 1600px) {
  .index-marketing .cont .index-marketing-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .index-marketing-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .index-marketing-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .index-marketing-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .index-marketing-title {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .index-marketing .cont .index-marketing-title {
    margin-bottom: 35px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .index-marketing-title {
    margin-bottom: 30px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .index-marketing-title {
    margin-bottom: 25px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .index-marketing-title {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .index-marketing-title {
    margin-bottom: 15px;
  }
}

.index-marketing .cont .list ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.index-marketing .cont .list ul li {
  flex-shrink: 0;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 2%;
  background-color: #000;
  border-radius: 20px;
  height: 330px;
}

@media (max-width: 1600px) {
  .index-marketing .cont .list ul li {
    border-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .list ul li {
    border-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .list ul li {
    border-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .list ul li {
    border-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li {
    border-radius: 10px;
  }
}

@media (max-width: 1600px) {
  .index-marketing .cont .list ul li {
    height: 280px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .list ul li {
    height: 250px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .list ul li {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .list ul li {
    height: 160px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li {
    height: 120px;
  }
}

.index-marketing .cont .list ul li:hover .item-img {
  transform: scale(1.1);
}

.index-marketing .cont .list ul li:nth-child(2):hover a::after, .index-marketing .cont .list ul li:nth-child(3):hover a::after, .index-marketing .cont .list ul li:nth-child(5):hover a::after {
  transform: scale(1);
}

.index-marketing .cont .list ul li:nth-child(1) {
  width: 29.5%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(1) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(2) {
  width: 36.5%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(2) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(2) a::after {
  background: linear-gradient(top left, rgba(255, 0, 60, 0), rgba(255, 0, 60, 0), rgba(255, 0, 60, 0.9));
}

.index-marketing .cont .list ul li:nth-child(3) {
  width: 29.5%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(3) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(3) a::after {
  background: linear-gradient(top left, rgba(249, 190, 0, 0), rgba(249, 190, 0, 0), rgba(249, 190, 0, 0.9));
}

.index-marketing .cont .list ul li:nth-child(4) {
  width: 36.8%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(4) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(4) .item-img {
  opacity: .8;
}

.index-marketing .cont .list ul li:nth-child(5) {
  width: 33.6%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(5) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(5) a::after {
  background: linear-gradient(top left, rgba(27, 57, 147, 0), rgba(27, 57, 147, 0), rgba(27, 57, 147, 0.9));
}

.index-marketing .cont .list ul li:nth-child(6) {
  width: 25.35%;
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li:nth-child(6) {
    width: 49%;
  }
}

.index-marketing .cont .list ul li:nth-child(6) .item-img {
  opacity: 1;
}

.index-marketing .cont .list ul li a {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.index-marketing .cont .list ul li a::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  transition: all .25s;
  transform: scale(0);
  transform-origin: bottom right;
}

.index-marketing .cont .list ul li .item-img {
  height: 100%;
  opacity: .6;
  position: relative;
  transition: all .3s;
}

.index-marketing .cont .list ul li .item-t {
  position: absolute;
  bottom: 10%;
  right: 10%;
  z-index: 3;
  color: #fff;
  text-align: right;
  font-size: 22px;
}

@media (max-width: 1600px) {
  .index-marketing .cont .list ul li .item-t {
    font-size: 20.8px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .list ul li .item-t {
    font-size: 19.6px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .list ul li .item-t {
    font-size: 18.4px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .list ul li .item-t {
    font-size: 17.2px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li .item-t {
    font-size: 16px;
  }
}

.index-marketing .cont .list ul li .item-icont {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: #fff;
  z-index: 2;
}

.index-marketing .cont .list ul li .item-icont .iconfont {
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-marketing .cont .list ul li .item-icont .iconfont {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-marketing .cont .list ul li .item-icont .iconfont {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-marketing .cont .list ul li .item-icont .iconfont {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-marketing .cont .list ul li .item-icont .iconfont {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-marketing .cont .list ul li .item-icont .iconfont {
    font-size: 30px;
  }
}

.index-news {
  overflow: hidden;
  padding-top: 100px;
}

@media (max-width: 1600px) {
  .index-news {
    padding-top: 90px;
  }
}

@media (max-width: 1366px) {
  .index-news {
    padding-top: 80px;
  }
}

@media (max-width: 1024px) {
  .index-news {
    padding-top: 70px;
  }
}

@media (max-width: 991px) {
  .index-news {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .index-news {
    padding-top: 50px;
  }
}

.index-news .cont {
  max-width: 1400px;
  box-sizing: content-box;
  margin: 0 auto;
  position: relative;
  padding-left: 140px;
  padding-right: 140px;
}

@media (max-width: 1600px) {
  .index-news .cont {
    padding-left: 112px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont {
    padding-left: 84px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont {
    padding-left: 56px;
  }
}

@media (max-width: 991px) {
  .index-news .cont {
    padding-left: 28px;
  }
}

@media (max-width: 767px) {
  .index-news .cont {
    padding-left: 0px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont {
    padding-right: 112px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont {
    padding-right: 84px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont {
    padding-right: 56px;
  }
}

@media (max-width: 991px) {
  .index-news .cont {
    padding-right: 28px;
  }
}

@media (max-width: 767px) {
  .index-news .cont {
    padding-right: 0px;
  }
}

.index-news .cont::after {
  content: '';
  display: block;
  width: 200%;
  height: 80%;
  background-color: #eef1f8;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 0;
  border-radius: 20px;
}

@media (max-width: 767px) {
  .index-news .cont::after {
    left: -10px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont::after {
    border-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont::after {
    border-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont::after {
    border-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .cont::after {
    border-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .cont::after {
    border-radius: 10px;
  }
}

.index-news .cont .index-news-title {
  font-family: 'itc-b';
  transform: translateY(-50%);
  color: #3a3a3a;
  font-size: 60px;
}

@media (max-width: 1600px) {
  .index-news .cont .index-news-title {
    font-size: 54px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .index-news-title {
    font-size: 48px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .index-news-title {
    font-size: 42px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .index-news-title {
    font-size: 36px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .index-news-title {
    font-size: 30px;
  }
}

.index-news .cont .flex-c {
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
}

@media (max-width: 767px) {
  .index-news .cont .flex-c {
    display: block;
  }
}

.index-news .cont .flex-c .left {
  flex-shrink: 0;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  width: 56%;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left {
    border-radius: 18px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left {
    border-radius: 16px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left {
    border-radius: 14px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left {
    border-radius: 12px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left {
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left {
    width: 100%;
  }
}

.index-news .cont .flex-c .left:hover .item-img img {
  transform: scale(1.05);
}

.index-news .cont .flex-c .left .item-img img {
  display: block;
  width: 100%;
  height: auto;
  transition: all .3s;
}

.index-news .cont .flex-c .left .item-t {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  color: #fff;
  font-family: 'itc-b';
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8));
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 32px;
  padding-left: 45px;
  padding-right: 45px;
  padding-top: 110px;
  padding-bottom: 35px;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left .item-t {
    font-size: 28.8px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left .item-t {
    font-size: 25.6px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left .item-t {
    font-size: 22.4px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left .item-t {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left .item-t {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left .item-t {
    padding-left: 39px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left .item-t {
    padding-left: 33px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left .item-t {
    padding-left: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left .item-t {
    padding-left: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left .item-t {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left .item-t {
    padding-right: 39px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left .item-t {
    padding-right: 33px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left .item-t {
    padding-right: 27px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left .item-t {
    padding-right: 21px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left .item-t {
    padding-right: 15px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left .item-t {
    padding-top: 96px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left .item-t {
    padding-top: 82px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left .item-t {
    padding-top: 68px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left .item-t {
    padding-top: 54px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left .item-t {
    padding-top: 40px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .left .item-t {
    padding-bottom: 31px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .left .item-t {
    padding-bottom: 27px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .left .item-t {
    padding-bottom: 23px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .left .item-t {
    padding-bottom: 19px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .left .item-t {
    padding-bottom: 15px;
  }
}

.index-news .cont .flex-c .right {
  flex-shrink: 0;
  width: 39%;
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right {
    width: 100%;
  }
}

.index-news .cont .flex-c .right .item {
  display: block;
  margin-top: 50px;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .right .item {
    margin-top: 43px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .right .item {
    margin-top: 36px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .right .item {
    margin-top: 29px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .right .item {
    margin-top: 22px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right .item {
    margin-top: 15px;
  }
}

.index-news .cont .flex-c .right .item .item-date {
  color: #1b3993;
  margin-bottom: 10px;
  font-size: 16px;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .right .item .item-date {
    margin-bottom: 9px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .right .item .item-date {
    margin-bottom: 8px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .right .item .item-date {
    margin-bottom: 7px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .right .item .item-date {
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right .item .item-date {
    margin-bottom: 5px;
  }
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .right .item .item-date {
    font-size: 15.2px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .right .item .item-date {
    font-size: 14.4px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .right .item .item-date {
    font-size: 13.6px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .right .item .item-date {
    font-size: 12.8px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right .item .item-date {
    font-size: 12px;
  }
}

.index-news .cont .flex-c .right .item .item-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'itc-b';
  position: relative;
  color: #3a3a3a;
  font-size: 26px;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .right .item .item-title {
    font-size: 23.6px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .right .item .item-title {
    font-size: 21.2px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .right .item .item-title {
    font-size: 18.8px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .right .item .item-title {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right .item .item-title {
    font-size: 14px;
  }
}

.index-news .cont .flex-c .right .item .item-title::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background-color: #666769;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all .3s;
}

.index-news .cont .flex-c .right .item .item-title:hover::after {
  width: 100%;
}

.index-news .cont .flex-c .right .more-link {
  text-align: right;
  margin-top: 130px;
}

@media (max-width: 1600px) {
  .index-news .cont .flex-c .right .more-link {
    margin-top: 108px;
  }
}

@media (max-width: 1366px) {
  .index-news .cont .flex-c .right .more-link {
    margin-top: 86px;
  }
}

@media (max-width: 1024px) {
  .index-news .cont .flex-c .right .more-link {
    margin-top: 64px;
  }
}

@media (max-width: 991px) {
  .index-news .cont .flex-c .right .more-link {
    margin-top: 42px;
  }
}

@media (max-width: 767px) {
  .index-news .cont .flex-c .right .more-link {
    margin-top: 20px;
  }
}
