@import "../../assets/styles/utils";

.index-banner{
    position: relative;
    @include res(height,100vh,(md:600px,sm:400px,xs:300px));
    .swiper-wrapper{
        .swiper-slide{
            overflow:hidden;
            display: flex;
            align-items: center;
            &.swiper-slide-active,&.swiper-slide-duplicate-active{
                .slide-inner{
                    b{
                        transform: scale(1);
                    }
                }
            }
            .slide-inner{
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                b{
                    display: block;
                    height: 100%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    transition: transform 4s;
                    transition-delay: .5s;
                    transform: scale(1.1);
                    transform-origin: center;
                }
            }
        }
    }
    .swiper-pagination{
        @include res(right,140px, 20 / 140);
        .swiper-pagination-bullet{
            opacity: 1;
            width: 10px;
            height: 10px;
            background-color: #fff;
            position: relative;
            transition: all .3s;
            &:not(:last-child){
                @include res(margin-bottom,25px, 10 / 25);
            }
            &.swiper-pagination-bullet-active{
                background-color: $red;
            }
        }
    }
    .common-circle{
        position: absolute;
        bottom: 5%;
        right: 5%;
        z-index: 1;
        color: #fff;
        .img .iconfont{
            color: #fff;
        }
    }
}

.index-about{
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    @include res(flex-direction,row-reverse,(xs:column-reverse));
    @include res(display,flex);
    @include res(padding-top,145px, 20 / 145);
    @include res(padding-bottom,185px, 20 / 185);
    &::after{
        content: '';
        display: block;
        border-radius: 30px;
        background-color: $color-bg;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transform-origin: center;
        transform: rotate(45deg) translate3d(-40%,-30%, 0);
        @include res(width,600px, 200 / 600);
        @include res(height,600px, 200 / 600);
    }
    .left{
        flex-shrink: 0;
        box-sizing: border-box;
        @include res(text-align,left,(sm:center));
        @include res(width,45.8%,(sm:100%));
        @include res(padding-left,200px, 0 / 200);
        img{
            display: block;
            height: auto;
            @include res(margin,0,(sm:0 auto));
            @include res(width,256px,160 / 256);
            @include res(transform,translateX(-13%));
        }
        .t{
            font-family: 'itc-b';
            text-transform: uppercase;
            line-height: .9;
            color: #3a3a3a;
            @include res(font-size,80px, 30 / 80);
            @include res(margin-top,20px, 10 / 20);
            @include res(margin-bottom,70px, 20 / 70);
            span{
                background-color: $yellow;
                color: #fff;
                line-height: 1;
                border-radius: 50px;
                display: inline-block;
                @include res(padding-left,30px, 10 / 30);
                @include res(padding-right,30px, 10 / 30);
                @include res(font-size,60px, 20 / 60);
            }
        }
    }
    .right{
        flex-shrink: 0;
        font-family: 'itc-b';
        @include res(margin-top,null,(sm:30px));
        @include res(margin-bottom,null,(sm:30px));
        @include res(width,54.2%,(sm:100%));
        .cont1{
            border-bottom: 1px solid #c6cbdb;
            color: $blue;
            @include res(font-size,40px, 20 / 40);
            @include res(padding-bottom,40px, 10 / 40);
            .swiper{
                .t1{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    // flex-wrap: wrap;
                    img{
                        height: auto;
                        @include res(margin-left,10px);
                        @include res(margin-right,20px,10 / 20);
                        @include res(width,141px, 100 / 141);
                    }
                }
                .t2{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    img{
                        @include res(margin-left,35px,10 / 35);
                    }
                }
            }
        }
        .cont2{
            color: $blue;
            border-bottom: 1px solid #c6cbdb;
            @include res(padding-bottom,20px, 10 / 20);
            @include res(padding-top,25px, 10 / 25);
            @include res(font-size,40px, 20 / 40);
            @include res(margin-bottom,50px, 10 / 50);
            .swiper{
                .t1{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    img{
                        @include res(width,354px, 200 / 354);
                        @include res(margin-left,40px, 15 / 40);
                    }
                }
                .t2{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    span{
                        &:first-child{
                            color: $red;
                            white-space: nowrap;
                        }
                    }
                    img{
                        border-radius: 10px;
                        @include res(width,220px, 100 / 220);
                        @include res(margin-left,65px, 5 / 65);
                        @include res(margin-right,50px, 5 / 50);
                    }
                }
            }
        }
        .cont3{
            color: $red;
            align-items: flex-start;
            justify-content: flex-start;
            @include res(display,flex,(xs:block));
            @include res(font-size,24px, 16 / 24);
            img{
                border-radius: 10px;
                height: auto;
                @include res(width,220px,(xs:100%));
                @include res(margin-bottom,0,(xs:10px));
                @include res(margin-right,35px, 10 / 35);
            }
        }
    }
}

.index-product-pc{
    position: relative;
    overflow: hidden;
    @include res(display,block,(md:none));
    &.fixed{
        .color-bg,.nav-cont,.text-cont{
            position: fixed;
        }
    }
    &.abs{
        .color-bg,.nav-cont,.text-cont{
            position: absolute;
            bottom: 0;
            top: auto;
        }
    }
    .color-bg{
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: $blue;
        transition: all .45s;
        @include res(border-top-right-radius,50px, 30 / 50);
        @include res(border-bottom-right-radius,50px, 30 / 50);
        @include res(width,93.75%);
    }
    .nav-cont{
        width: 50%;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        @include res(padding-left,120px, 10 / 120);
        @include res(padding-top,10%);
        &::after,&::before{
            content: '';
            display: block;
            border-radius: 30px;
            background-color: $color-bg;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            transform: rotate(45deg) translate3d(-50%,70%,0);
            opacity: .1;
            @include res(width,600px, 200 / 600);
            @include res(height,600px, 200 / 600);
        }
        &::after{
            transform: rotate(45deg) translate3d(-58%,58%,0);
        }
        .nav-title{
            color: #fff;
            @include res(font-size,20px, 16 / 20);
            @include res(margin-bottom,40px, 20 / 40);
        }
        .nav-list{
            font-family: 'itc-b';
            white-space: nowrap;
            line-height: 1;
            @include res(font-size,90px, 40 / 90);
            ul{
                li{
                    cursor: pointer;
                    color: transparent;
                    -webkit-text-stroke: 1px rgba(255,255,255,.7);
                    -webkit-font-smoothing: antialiased;
                    transition: all .3s;
                    text-transform: uppercase;
                    &.active{
                        color: #fff;
                    }
                }
            }
        }
    }
    .text-cont{
        width: 42%;
        height: 100vh;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
        .item{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            text-align: right;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;
            @include res(right,260px, 50 / 260);
            &.active{
                opacity: 1;
                visibility: visible;
            }
            .t{
                color: #fff;
                line-height: 1.6;
                @include res(margin-bottom,55px, 20 / 55);
            }
        }
    }
    .img-cont{
        @include res(padding-left,18.22%);
        .item{
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .img{
                width: 100%;
                height: 77%;
                background-position: center;
                background-size: cover;
                position: relative;
                overflow: hidden;
                @include res(border-top-left-radius,20px, 10 / 20);
                @include res(border-bottom-left-radius,20px, 10 / 20);
                &::before{
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0,0,0,.2);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                &::after{
                    content: '';
                    display: block;
                    width: 50%;
                    height: 100%;
                    background: linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,.8));
                    position: absolute;
                    top: 0;
                    right: 0;
                }
            }
        }
    }
}

.index-product-mob{
    @include res(display,none,(md:block));
    .index-product-title{
        font-family: 'itc-b';
        color: #3a3a3a;
        @include res(padding-left,260px, 10 / 260);
        @include res(padding-right,260px, 10 / 260);
        @include res(font-size,60px, 30 / 60);
        @include res(margin-bottom,40px, 15 / 40);
    }
    .product-list{
        ul{
            li{
                position: relative;
                @include res(margin-top,60px, 10 / 60);
                @include res(padding-left,120px, 15 / 120);
                @include res(padding-top,120px, 15 / 120);
                @include res(padding-bottom,120px, 15 / 120);
                &::after{
                    content: '';
                    display: block;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: -1;
                    transition: all .45s;
                    @include res(border-top-right-radius,50px, 30 / 50);
                    @include res(border-bottom-right-radius,50px, 30 / 50);
                    @include res(width,93.75%);
                }
                &:nth-child(1){
                    &::after{background-color: $blue;}
                }
                &:nth-child(2){
                    &::after{background-color: $red;}
                }
                &:nth-child(3){
                    &::after{background-color: $yellow;}
                }
                &:nth-child(4){
                    &::after{background-color: $orange;}
                }
                &:nth-child(5){
                    &::after{background-color: $orange;}
                }
                &:nth-child(6){
                    &::after{background-color: $skyblue;}
                }
                .cont{
                    position: relative;
                    .item-img{
                        overflow: hidden;
                        background-position: center;
                        background-size: cover;
                        position: relative;
                        @include res(border-top-left-radius,20px, 10 / 20);
                        @include res(border-bottom-left-radius,20px, 10 / 20);
                        @include res(height,500px,(sm:400px,xs:300px));
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0,0,0,.4);
                            position: absolute;
                            top: 0;
                            left: 0;
                        }
                    }
                    .item-t{
                        box-sizing: border-box;
                        width: 100%;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        z-index: 1;
                        transform: translateY(-50%);
                        text-align: center;
                        @include res(padding-right,100px, 20 / 100);
                        @include res(padding-left,100px, 20 / 100);
                        @include res(padding-top,20px, 10 / 20);
                        @include res(padding-bottom,20px, 10 / 20);
                        .title{
                            font-family: 'itc-b';
                            line-height: 1;
                            color: #fff;
                            text-transform: uppercase;
                            @include res(font-size,90px, 40 / 90);
                            @include res(margin-bottom,55px, 20 / 55);
                        }
                        .desc{
                            color: #fff;
                            line-height: 1.6;
                            @include res(margin-bottom,55px, 20 / 55);
                        }
                    }
                }
            }
        }
    }
}


.index-marketing{
    position: relative;
    overflow: hidden;
    @include res(padding-top,160px, 80 / 160);
    @include res(padding-bottom,60px, 20 / 60);
    .top-scroll-text{
        font-family: 'itc-b';
        color: #eef1f8;
        position: absolute;
        top: 0;
        right: -10%;
        white-space: nowrap;
        line-height: .7;
        @include res(font-size,160px, 60 / 160);
    }
    .common-circle{
        color: #d8d8d8;
        @include res(top,30%,(sm:1%));
        @include res(left,30px, 10 / 30);
        .img .iconfont{
            color: rgba(0,0,0,.2);
        }
    }
    .cont{
        max-width: 1400px;
        box-sizing: content-box;
        margin: 0 auto;
        @include res(padding-left,140px, 0 / 140);
        @include res(padding-right,140px, 0 / 140);
        .index-marketing-title{
            font-family: 'itc-b';
            color: #3a3a3a;
            @include res(font-size,60px, 30 / 60);
            @include res(margin-bottom,40px, 15 / 40);
        }
        .list{
            ul{
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                li{
                    flex-shrink: 0;
                    border-radius: 20px;
                    overflow: hidden;
                    margin-bottom: 2%;
                    background-color: #000;
                    @include res(border-radius,20px, 10 / 20);
                    @include res(height,330px,(lg:280px,mmd:250px,md:200px,sm:160px,xs:120px));
                    &:hover{
                        .item-img{
                            transform: scale(1.1);
                        }
                    }
                    &:nth-child(2),&:nth-child(3),&:nth-child(5){
                        &:hover{
                            a{
                                &::after{
                                    transform: scale(1);
                                }
                            }
                        }
                    }
                    // &:nth-child(1),&:nth-child(4),&:nth-child(6){
                    //     &:hover{
                    //         .item-img{
                    //             transform: scale(1.1);
                    //         }
                    //     }
                    // }
                    &:nth-child(1){
                        @include res(width,29.5%,(xs:49%));
                    }
                    &:nth-child(2){
                        @include res(width,36.5%,(xs:49%));
                        a{
                            &::after{
                                background: linear-gradient(top left,rgba(255,0,60,0),rgba(255,0,60,0),rgba(255,0,60,.9));
                            }
                        }
                    }
                    &:nth-child(3){
                        @include res(width,29.5%,(xs:49%));
                        a{
                            &::after{
                                background: linear-gradient(top left,rgba(249,190,0,0),rgba(249,190,0,0),rgba(249,190,0,.9));
                            }
                        }
                    }
                    &:nth-child(4){
                        @include res(width,36.8%,(xs:49%));
                        .item-img{
                            opacity: .8;
                        }
                    }
                    &:nth-child(5){
                        @include res(width,33.6%,(xs:49%));
                        a{
                            &::after{
                                background: linear-gradient(top left,rgba(27,57,147,0),rgba(27,57,147,0),rgba(27,57,147,.9));
                            }
                        }
                    }
                    &:nth-child(6){
                        @include res(width,25.35%,(xs:49%));
                        .item-img{
                            opacity: 1;
                        }
                    }
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                        position: relative;
                        &::after{
                            content: '';
                            display: block;
                            width: 100%;
                            height: 100%;
                            background-color: #fff;
                            position: absolute;
                            top: 0;
                            right: 0;
                            transition: all .25s;
                            transform: scale(0);
                            transform-origin: bottom right;
                        }
                    }
                    .item-img{
                        height: 100%;
                        opacity: .6;
                        position: relative;
                        transition: all .3s;
                    }
                    .item-t{
                        position: absolute;
                        bottom: 10%;
                        right: 10%;
                        z-index: 3;
                        color: #fff;
                        text-align: right;
                        @include res(font-size,22px, 16 / 22);
                    }
                    .item-icont{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate3d(-50%,-50%,0);
                        color: #fff;
                        z-index: 2;
                        .iconfont{
                            @include res(font-size,60px, 30 / 60);
                        }
                    }
                }
            }
        }
    }
}

.index-news{
    overflow: hidden;
    @include res(padding-top,100px, 50 / 100);
    .cont{
        max-width: 1400px;
        box-sizing: content-box;
        margin: 0 auto;
        position: relative;
        @include res(padding-left,140px, 0 / 140);
        @include res(padding-right,140px, 0 / 140);
        &::after{
            content: '';
            display: block;
            width: 200%;
            height: 80%;
            background-color: $color-bg;
            position: absolute;
            top: 0;
            z-index: -1;
            @include res(left,0,(xs:-10px));
            @include res(border-radius,20px, 10 / 20);
        }
        .index-news-title{
            font-family: 'itc-b';
            transform: translateY(-50%);
            color: #3a3a3a;
            @include res(font-size,60px, 30 / 60);
            // @include res(margin-bottom,40px, 15 / 40);
        }
        .flex-c{
            align-items: flex-start;
            justify-content: space-between;
            @include res(display,flex,(xs:block));
            .left{
                flex-shrink: 0;
                position: relative;
                overflow: hidden;
                @include res(border-radius,20px, 10 / 20);
                @include res(width,56%,(xs:100%));
                &:hover{
                    .item-img{
                        img{
                            transform: scale(1.05);
                        }
                    }
                }
                .item-img{
                    img{
                        display: block;
                        width: 100%;
                        height: auto;
                        transition: all .3s;
                    }
                }
                .item-t{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    color: #fff;
                    font-family: 'itc-b';
                    background: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.8));
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    @include res(font-size,32px, 16 / 32);
                    @include res(padding-left,45px, 15 / 45);
                    @include res(padding-right,45px, 15 / 45);
                    @include res(padding-top,110px, 40 / 110);
                    @include res(padding-bottom,35px, 15 / 35);
                }
            }
            .right{
                flex-shrink: 0;
                @include res(width,39%,(xs:100%));
                .item{
                    display: block;
                    @include res(margin-top,50px, 15 / 50);
                    .item-date{
                        color: $blue;
                        @include res(margin-bottom,10px, 5 / 10);
                        @include res(font-size,16px, 12 / 16);
                    }
                    .item-title{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-family: 'itc-b';
                        position: relative;
                        color: #3a3a3a;
                        @include res(font-size,26px, 14 / 26);
                        &::after{
                            content: '';
                            display: block;
                            width: 0;
                            height: 1px;
                            background-color: #666769;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            transition: all .3s;
                        }
                        &:hover{
                            &::after{
                                width: 100%;
                            }
                        }
                    }
                }
                .more-link{
                    text-align: right;
                    @include res(margin-top,130px, 20 / 130);
                }
            }
        }
    }
}

